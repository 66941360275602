<template>
    <div class="CustomerAccountCard">
        <el-divider content-position="left">费用明细</el-divider>
        <el-row>
            <el-col :span="24" style="text-align: left;margin-bottom: 10px;cursor: pointer;" v-if="form.driverQuotationFlag==0">
                <el-tag @click="viewQuotation">{{form.quotationType=='customer'?'客户报价单':'承运商报价单'}}</el-tag>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="2" class="infoCost">起步价</el-col>
            <el-col :span="22" class="infoCost">{{form.costBean && form.costBean.began!=0?form.costBean.began:''}}</el-col>
        </el-row>
        <el-row>
            <el-col :span="5" class="infoCost">超里程续费</el-col>
            <el-col :span="7" class="infoCost">{{form.costBean && form.costBean.mileage!=0?form.costBean.mileage:''}}</el-col>
            <el-col :span="5" class="infoCost">超点位续费</el-col>
            <el-col :span="7" class="infoCost">{{form.costBean && form.costBean.pointNum!=0?form.costBean.pointNum:''}}</el-col>
        </el-row>
        <el-row>
            <el-col :span="5" class="infoCost">超件数续费</el-col>
            <el-col :span="7" class="infoCost">{{form.costBean && form.costBean.num!=0?form.costBean.num:''}}</el-col>
            <el-col :span="5" class="infoCost">超重量续费</el-col>
            <el-col :span="7" class="infoCost">{{form.costBean && form.costBean.weight!=0?form.costBean.weight:''}}</el-col>
        </el-row>
        <el-row>
            <el-col :span="5" class="infoCost">超体积续费</el-col>
            <el-col :span="19" class="infoCost">{{form.costBean && form.costBean.size!=0?form.costBean.size:''}}</el-col>
        </el-row>
        <el-row style="margin-bottom: 10px;" v-if="form.quotationType=='customer'">
            <el-col :span="5">折扣比例:</el-col>
            <el-col :span="19">{{form.costBean && form.costBean.discount!=0?(form.costBean.discount+'%'):''}}</el-col>
        </el-row>
        <el-row style="margin-bottom: 20px;color: red;">
            <el-col :span="5" class="infoCost">{{form.quotationType=='customer'?'客户运费合计':'司机运费合计'}}:</el-col>
            <el-col :span="19" class="infoCost">{{form.costBean && form.costBean.total!=0?form.costBean.total:''}}元</el-col>
        </el-row>

        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-row>
                <el-col :span="6" class="infoCost">
                    <el-form-item label="补贴金额" prop="bt">
                        <el-input-number v-model="form.costBean.bt" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6" class="infoCost">
                    <el-form-item label="服务罚款" prop="fwfk">
                        <el-input-number v-model="form.costBean.fwfk" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6" class="infoCost">
                    <el-form-item label="货损扣款" prop="spkk">
                        <el-input-number v-model="form.costBean.spkk" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6" class="infoCost">
                    <el-form-item label="物资赔款" prop="name">
                        <el-input-number v-model="form.costBean.wzpk" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <el-row style="margin-bottom: 20px;color: red;">
            <el-col :span="5" class="infoCost">结算金额:</el-col>
            <el-col :span="19" class="infoCost">{{form.costBean && form.costBean.jsjj!=0?form.costBean.jsjj:''}}元</el-col>
        </el-row>

        <el-collapse >
            <el-collapse-item title="运单基础信息" name="wayBillInfo">
                <el-row>
                    <el-col :span="2" class="infoTitle">运单编号</el-col>
                    <el-col :span="10">
                        <el-tag @click="viewWayBill" type="success" style="cursor: pointer">{{form.waybillCode}}</el-tag>
                    </el-col>
                    <el-col :span="2" class="infoTitle">渠道名称</el-col>
                    <el-col :span="10">{{form.channelName}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">客户类型</el-col>
                    <el-col :span="10">{{form.customerType}}</el-col>
                    <el-col :span="2" class="infoTitle">客户名称</el-col>
                    <el-col :span="10">{{form.customerName}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">委托单号</el-col>
                    <el-col :span="10">{{form.authCode}}</el-col>
                    <el-col :span="2" class="infoTitle">项目名称</el-col>
                    <el-col :span="10">{{form.projectName}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">销售类型</el-col>
                    <el-col :span="10">{{form.saleType}}</el-col>
                    <el-col :span="2" class="infoTitle">货物类型</el-col>
                    <el-col :span="10">{{form.goodsType}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">要求装货时间</el-col>
                    <el-col :span="10">{{form.loadTime}}</el-col>
                    <el-col :span="2" class="infoTitle">要求送达时间</el-col>
                    <el-col :span="10">{{form.deliveryTime}}</el-col>
                </el-row>
            </el-collapse-item>
            <el-collapse-item title="车次信息" name="wayBillCarInfo">
                <el-row>
                    <el-col :span="2" class="infoTitle">车次号</el-col>
                    <el-col :span="10">
                        <el-tag @click="viewWayBillCar" type="danger" style="cursor: pointer">{{form.carOrder}}</el-tag>
                    </el-col>
                    <el-col :span="2" class="infoTitle">派车时间</el-col>
                    <el-col :span="10">{{form.sendCarTime}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">派车人</el-col>
                    <el-col :span="10">{{form.sendCarPerson}}</el-col>
                    <el-col :span="2" class="infoTitle">状态</el-col>
                    <el-col :span="10">{{form.carOrderStatus}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">车队名称</el-col>
                    <el-col :span="10">{{form.fleetName}}</el-col>
                    <el-col :span="2" class="infoTitle">车型</el-col>
                    <el-col :span="10">{{form.cxName}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">车牌号</el-col>
                    <el-col :span="10">{{form.carNum}}</el-col>
                    <el-col :span="2" class="infoTitle">司机姓名</el-col>
                    <el-col :span="10">{{form.driverName}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">司机电话</el-col>
                    <el-col :span="10">{{form.driverPhone}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">装货点</el-col>
                    <el-col :span="10">{{form.loadPlaceInfo}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">卸货点</el-col>
                    <el-col :span="10">{{form.unLoadPlaceInfo}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">配送点位数</el-col>
                    <el-col :span="10">{{form.pointNum}}</el-col>
                    <el-col :span="2" class="infoTitle">里程</el-col>
                    <el-col :span="10">{{form.mileage}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">货物名称</el-col>
                    <el-col :span="10">{{form.goodsName}}</el-col>
                    <el-col :span="2" class="infoTitle">件数</el-col>
                    <el-col :span="10">{{form.num}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">体积</el-col>
                    <el-col :span="10">{{form.size}}</el-col>
                    <el-col :span="2" class="infoTitle">重量</el-col>
                    <el-col :span="10">{{form.weight}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">代收货款</el-col>
                    <el-col :span="10">{{form.collectionMoney}}</el-col>
                    <el-col :span="2" class="infoTitle">装载量</el-col>
                    <el-col :span="10">{{form.capacityPercent}}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="2" class="infoTitle">备注</el-col>
                    <el-col :span="10">{{form.remark}}</el-col>
                </el-row>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    import AccountCardHelper from "./AccountCardHelper.js";
    export default AccountCardHelper;
</script>

<style scoped>
    .CustomerAccountCard{
        width: 100%;
    }
    .infoCost{
        font-size: 20px;padding-right: 20px;margin-bottom: 5px;
    }
    .infoTitle{
        text-align: right;padding-right: 10px;
    }
</style>